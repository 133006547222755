import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../styles/components/button.emotion";
import { DialogForm } from "../../styles/components/dialog-form/dialog-form.emotion";
import { Error, FormControl, Label } from "../../styles/utils/form.emotion";
import { Wrapper } from "../../styles/utils/global.emotion";
import * as React from "react";
import { Message } from "../../models/message.model";
import api from "../../service/api";
import VMasker from "vanilla-masker";
import { useAlert } from "../../context/alert.context";
import { event } from "../../lib/gtag";
import { Contact } from "../../models/contact.model";
import classNames from "classnames";

interface Form {
  name: string;
  email: string;
  phone: string;
  message: string;
}

interface Props {
  contact?: Contact;
  active: boolean;
  onDismiss: () => void;
}

export default function DialogFormComponent({
  contact,
  active,
  onDismiss,
}: Props) {
  const { presentAlert } = useAlert();

  const {
    register,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>();

  const [isSubmitting, setSubmitting] = React.useState(false);
  const [openWhats, setOpenWhats] = React.useState(false);

  const onSubmit: SubmitHandler<Form> = async (fields) => {
    setSubmitting(true);

    const data = {
      ...new Message(),
      subject: "Contato WhatsApp",
      name: fields?.name,
      phone: fields?.phone,
    } as Message;

    const text = `Olá, vim pelo site! Gostaria de mais informações. Vocês podem me auxiliar?`;

    const response = await api.post("message", data);

    setOpenWhats(!openWhats);

    onDismiss();

    const blob = `https://wa.me/55${contact?.whatsapp}?text=${text}`;
    const a = document.createElement("a");
    a.href = blob;
    a.target = blob;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    if (response?.status === 200) {
      // presentAlert({
      //   title: "Sucesso!",
      //   message:
      //     "Obrigado por preencher nosso formulário, já iremos direcionar você para o nosso whatsapp",
      //   buttonText: "Certo!",
      // });

      event({
        action: "form_home_complet_submitted",
        category: "generate_lead",
        label: "Formúlario Home Rodapé",
        value: JSON.stringify(data),
      });

      reset({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    }

    setSubmitting(false);
  };
  return (
    <>
      <DialogForm active={active}>
        <div className="box">
          Olá. que tal um orçamento sem custo? Me infome os campos abaixo para
          iniciarmos uma conversa sem compromisso.
        </div>
        <form id="form-chat" onSubmit={handleSubmit(onSubmit)}>
          <Wrapper
            flexDirection="row"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <FormControl size={100}>
              <Label>Nome</Label>
              <input
                id="form-chat-name"
                {...register("name", {
                  required: "Este campo é requirido",
                })}
                placeholder="Digite seu nome"
              />
              {errors?.name ? (
                <Error>
                  <i className="fa-solid fa-info-circle"></i>
                  <span>{errors?.name?.message}</span>
                </Error>
              ) : null}
            </FormControl>

            <FormControl size={100}>
              <Label>Telefone</Label>
              <input
                id="form-chat-phone"
                {...register("phone", {
                  required: "Este campo é requirido",
                })}
                type="tel"
                placeholder="Digite seu telefone"
                onChange={(ev) => {
                  const phoneFormatted = VMasker.toPattern(
                    ev.target.value,
                    ev.target.value.length > 14
                      ? "(99) 99999-9999"
                      : "(99) 9999-9999"
                  );
                  setValue("phone", phoneFormatted);
                }}
              />
              {errors?.phone ? (
                <Error>
                  <i className="fa-solid fa-info-circle"></i>
                  <span>{errors?.phone?.message}</span>
                </Error>
              ) : null}
            </FormControl>

            <Button buttonStyle="default" type="submit">
              <span>Iniciar conversa</span>
            </Button>
          </Wrapper>
        </form>
      </DialogForm>
      <div
        className={classNames("overlay", { active: active })}
        onClick={onDismiss}
      ></div>
    </>
  );
}
