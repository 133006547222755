import styled from "@emotion/styled";
import React from "react";
import { useGlobal } from "../../context/global.context";
import { Contact } from "../../models/contact.model";

interface Props {
  onDismiss: () => void;
}

const WhatsappButtonComponent: React.FC<Props> = ({ onDismiss }) => {
  const text =
    "Olá, vim pelo site! Gostaria de mais informações. Vocês podem me auxiliar?";

  const { contact } = useGlobal();
  return (
    <Styles>
      {/* <a
        className="button"
        target="_blank"
        rel="noreferrer"
        href={`https://wa.me/55${contact?.whatsapp}?text=${text}`}
      >
        <i className="fab fa-whatsapp"></i>
      </a> */}
      <button className="button" type="button" onClick={onDismiss}>
        <i className="fab fa-whatsapp"></i>
      </button>
    </Styles>
  );
};

export default WhatsappButtonComponent;

const Styles = styled("section")<{
  active?: boolean;
}>`
  .button {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    display: block;
    display: flex;
    align-items: center;
    margin-left: auto;
    justify-content: center;
    font-size: 30px;
    background: ${(props) => props.theme.colors.whatsApp};
    animation: shake-animation 2s ease infinite;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 10;

    .fab {
      color: #fff;
    }

    &:hover {
      text-decoration: none;
    }
  }

  @keyframes shake-animation {
    0% {
      transform: translate(0, 0);
    }
    1.78571% {
      transform: translate(5px, 0);
    }
    3.57143% {
      transform: translate(0, 0);
    }
    5.35714% {
      transform: translate(5px, 0);
    }
    7.14286% {
      transform: translate(0, 0);
    }
    8.92857% {
      transform: translate(5px, 0);
    }
    10.71429% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }
`;
