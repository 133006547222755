import classNames from "classnames";
import { useRouter } from "next/router";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { HeaderSearch } from "../../styles/components/header/header-search.emotion";
import { Wrapper } from "../../styles/utils/global.emotion";

interface Form {
  search: string;
}

export default function HeaderSearchComponent() {
  const router = useRouter();
  const { register, handleSubmit, setFocus } = useForm<Form>();
  const [isOpen, setOpen] = React.useState(false);

  const onSubmit: SubmitHandler<Form> = (data) => {
    router.push({
      pathname: "/busca",
      query: {
        search: data?.search?.toLocaleLowerCase(),
        page: 1,
      },
    });
  };

  return (
    <HeaderSearch>
      <button
        onClick={() => setOpen(!isOpen)}
        data-hidden-desktop
        className="toggle-search"
      >
        <i className="fa-solid fa-search"></i>
      </button>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classNames({ active: isOpen })}
      >
        <Wrapper flexDirection="row" alignItems="center" gap="1em">
          <input
            {...register("search")}
            type="text"
            placeholder="Pesquise por um produto"
          />
          <i className="fa-solid fa-search"></i>
        </Wrapper>
      </form>
      <div
        className={classNames("overlay", { active: isOpen })}
        data-hidden-desktop
        onClick={() => setOpen(false)}
      ></div>
    </HeaderSearch>
  );
}
