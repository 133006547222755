import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../styles/components/button.emotion";
import { DialogForm } from "../../styles/components/dialog-form/dialog-form.emotion";
import { Error, FormControl, Label } from "../../styles/utils/form.emotion";
import { Wrapper } from "../../styles/utils/global.emotion";
import * as React from "react";
import { Message } from "../../models/message.model";
import api from "../../service/api";
import VMasker from "vanilla-masker";
import { useAlert } from "../../context/alert.context";
import { event } from "../../lib/gtag";
import { Contact } from "../../models/contact.model";
import classNames from "classnames";
import { DialogCatalog } from "../../styles/components/dialog-form/dialog-catolog.emotion";
import Link from "next/link";

interface Form {
  name: string;
  email: string;
  phone: string;
  message: string;
}

interface Props {
  contact?: Contact;
  active: boolean;
  onDismiss: () => void;
}

export default function DialogCatalogComponent({
  contact,
  active,
  onDismiss,
}: Props) {
  const { presentAlert } = useAlert();

  const {
    register,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>();

  const [isSubmitting, setSubmitting] = React.useState(false);
  const [isSubmitSuccessful, setSubmitSuccessful] = React.useState(false);

  const onSubmit: SubmitHandler<Form> = async (fields) => {
    setSubmitting(true);

    const data = {
      ...new Message(),
      subject: "Catálogo Virtual",
      name: fields?.name,
      phone: fields?.phone,
    } as Message;

    const response = await api.post("message", data);

    if (response?.status === 200) {
      event({
        action: "form_home_complet_submitted",
        category: "generate_lead",
        label: "Formúlario Catálogo",
        value: JSON.stringify(data),
      });

      reset({
        name: "",
        email: "",
        phone: "",
      });
    }

    setSubmitSuccessful(true);
    setSubmitting(false);
  };
  return (
    <>
      <DialogCatalog active={active}>
        <div className="box">
          {isSubmitSuccessful
            ? "Clique no link abaixo para acessar o catálogo virtual"
            : "Para ter acesso ao catálogo, por favor, preencha as informações necessárias."}
        </div>
        {isSubmitSuccessful ? (
          <Wrapper
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Link
              href="https://yourway-digital-storage-br.s3.sa-east-1.amazonaws.com/bomarzo-site/_Cat%C3%A1logo+Pe%C3%A7as+-+Bomarzo+Design+(OUTUBRO+2024).pdf"
              target="_blank"
            >
              <Button buttonStyle="default" onClick={onDismiss}>
                <span>Catálogo de peças</span>
              </Button>
            </Link>
            <Link
              href="https://yourway-digital-storage-br.s3.sa-east-1.amazonaws.com/drm-site/CatA%C2%A1logoVasos-BomarzoDesign%28OUTUBRO2024%29_026b6ab1-106d-4d45-8325-98faac1772e2.pdf"
              target="_blank"
            >
              <Button buttonStyle="default" onClick={onDismiss}>
                <span>Catálogo de vasos</span>
              </Button>
            </Link>
          </Wrapper>
        ) : (
          <form id="form-catalog" onSubmit={handleSubmit(onSubmit)}>
            <Wrapper
              flexDirection="row"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <FormControl size={100}>
                <Label>Nome</Label>
                <input
                  id="form-catalog-name"
                  {...register("name", {
                    required: "Este campo é requirido",
                  })}
                  placeholder="Digite seu nome"
                />
                {errors?.name ? (
                  <Error>
                    <i className="fa-solid fa-info-circle"></i>
                    <span>{errors?.name?.message}</span>
                  </Error>
                ) : null}
              </FormControl>

              <FormControl size={100}>
                <Label>E-mail</Label>
                <input
                  id="form-catalog-email"
                  {...register("email", {
                    required: "Este campo é requirido",
                  })}
                  placeholder="Digite seu melhor e-mail"
                  type="email"
                />
                {errors?.email ? (
                  <Error>
                    <i className="fa-solid fa-info-circle"></i>
                    <span>{errors?.email?.message}</span>
                  </Error>
                ) : null}
              </FormControl>

              <FormControl size={100}>
                <Label>Telefone</Label>
                <input
                  id="form-catalog-phone"
                  {...register("phone", {
                    required: "Este campo é requirido",
                  })}
                  type="tel"
                  placeholder="Digite seu telefone"
                  onChange={(ev) => {
                    const phoneFormatted = VMasker.toPattern(
                      ev.target.value,
                      ev.target.value.length > 14
                        ? "(99) 99999-9999"
                        : "(99) 9999-9999"
                    );
                    setValue("phone", phoneFormatted);
                  }}
                />
                {errors?.phone ? (
                  <Error>
                    <i className="fa-solid fa-info-circle"></i>
                    <span>{errors?.phone?.message}</span>
                  </Error>
                ) : null}
              </FormControl>

              <Button
                buttonStyle="default"
                type="submit"
                disabled={isSubmitting}
              >
                <span>Obter catálogo</span>
              </Button>
            </Wrapper>
          </form>
        )}
      </DialogCatalog>
      <div
        className={classNames("overlay", { active: active })}
        onClick={onDismiss}
      ></div>
    </>
  );
}
