import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useMediaQuery } from "../../utils/responsive.emotion";

export const DialogForm = styled.div<{ active: boolean }>`
  position: fixed;
  bottom: 1.8rem;
  right: 1.8rem;
  width: 25vw;
  background-color: ${(props) => props.theme.colors.gray_light};
  padding: 1.25rem 1.8rem;
  border-radius: 12px;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  pointer-events: none;
  z-index: 10000;
  .box {
    background-color: ${(props) => props.theme.colors.white};
    padding: 0.8rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    font-size: 14px;
  }
  h2 {
    font-size: 1.25em;
    font-weight: 700;
    color: ${(props) => props.theme.colors.text};
    text-align: center;
    margin-bottom: 1.25rem;
  }
  ${(props) =>
    props.active &&
    css`
      opacity: 1;
      pointer-events: all;
    `}

  ${() => useMediaQuery("sm", "max")} {
    width: 90%;
    padding: 2rem;
    right: 50%;
    transform: translate(50%, 0);
  }
`;
