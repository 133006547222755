import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useMediaQuery } from "./responsive.emotion";

export const FormControl = styled.div<{
  size?: number;
  isTextArea?: boolean;
  themeColor?: "outline";
}>`
  position: relative;

  margin-bottom: 1rem;
  transition: var(--transition);
  width: 100%;
  flex: 0 0 100%;

  ${() => useMediaQuery("sm")} {
    flex: ${(props) => (props.size ? `0 0 ${props?.size}%` : "0 0 100%")};
    width: ${(props) => (props.size ? `${props?.size}%` : "100%")};
  }

  textarea {
    resize: none;
    height: auto;
    min-height: 120px;
  }

  input,
  select,
  textarea {
    order: 0;
    border: 0;
    font-size: 1rem;
    transition: all 180ms ease-in-out;
    -webkit-appearance: none;
    border-radius: 0;
    color: ${(props) => props.theme.colors.dark};
    background: transparent;
    display: block;
    width: 100%;
    line-height: 1;
    border: 2px solid transparent;
    padding: 0.5rem 0.5rem;
    background-color: ${(props) => props.theme.colors.white};

    &:focus {
      border-color: ${(props) => props.theme.colors.green};
    }

    &::placeholder {
      font-size: 0.875rem;
    }

    &:focus::placeholder {
      color: currentColor;
      opacity: 0.6;
    }

    &:required:valid {
      &::before {
        content: "*";
      }
    }
  }
  select {
    -webkit-appearance: button;
  }
  ${(props) =>
    props.isTextArea &&
    css`
      min-height: 10rem;
    `}

  ${(props) =>
    props.themeColor === "outline" &&
    css`
      border-bottom-color: ${props.theme.colors.text};

      input,
      textarea {
        color: ${props.theme.colors.text};
        border-color: ${props.theme.colors.green};
      }

      label {
        color: ${props.theme.colors.text};
      }
    `}
`;

export const Label = styled.label`
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 0.625rem;
  white-space: nowrap;
  display: block;
  transition: all 180ms ease-in-out;
  color: ${(props) => props.theme.colors.dark};
`;

export const Error = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  margin-top: 1em;

  .fa-solid {
    color: ${(props) => props.theme.colors.danger};
  }

  span {
    font-size: 0.85rem;
    margin-left: 0.4rem;
    color: ${(props) => props.theme.colors.danger};
    font-weight: 500;
  }
`;
