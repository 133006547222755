import * as React from "react";
import classNames from "classnames";
import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import { Header } from "../../styles/components/header/header.emotion";
import { Container, Wrapper } from "../../styles/utils/global.emotion";
import HeaderSearchComponent from "./header-search.component";
import { Button } from "../../styles/components/button.emotion";
import DialogCatalogComponent from "../dialog/dialog-catalog.component";

const HeaderCart = dynamic(() => import("./header-cart.component"), {
  ssr: false,
});

const HeaderCategories = dynamic(
  () => import("./header-categories.component"),
  {
    ssr: false,
  }
);

const HeaderTop = dynamic(() => import("./header-top.component"), {
  ssr: false,
});

export default function HeaderComponent() {
  const [active, setActive] = React.useState(false);
  const [isOpen, setOpen] = React.useState(false);
  const [isCatalogOpen, setCatalogOpen] = React.useState(false);
  const [isOpenCategories, setOpenCategories] = React.useState(false);

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        const scrollY = window.scrollY;
        setActive(scrollY > 60);
      });
    }
  }, []);

  return (
    <>
      <Header active={active}>
        <HeaderTop
          isOpen={isOpen}
          onToggleMenu={() => setOpen(!isOpen)}
          onToggleCategories={() => setOpenCategories(!isOpenCategories)}
        />
        <Container>
          <nav>
            <Wrapper flexDirection="row" alignItems="center">
              <Link href="/" className="logo" data-hidden-mobile>
                <Image src="/logo.png" alt="Bomarzo." width={180} height={31} />
              </Link>
              <ul
                className={classNames("menu-institutional", { active: isOpen })}
              >
                <li>
                  <Link href="/">
                    <span>Início</span>
                  </Link>
                </li>
                <li>
                  <Link href="/sobre">
                    <span>Sobre</span>
                  </Link>
                </li>
                <li data-hidden-mobile>
                  <Link href="/produtos">
                    <span>Produtos</span>
                  </Link>
                </li>
                <li data-hidden-desktop>
                  <a onClick={() => setOpenCategories(!isOpenCategories)}>
                    <span>Produtos</span>
                  </a>
                </li>
                <li>
                  <Link href="/contato">
                    <span>Contato</span>
                  </Link>
                </li>
                <li onClick={() => setOpen(false)}>
                  <button type="button" onClick={() => setCatalogOpen(true)}>
                    <span>Catálogo Virtual</span>
                  </button>
                </li>
              </ul>
              <div data-hidden-mobile>
                <HeaderSearchComponent />
              </div>
              <div data-hidden-mobile>
                <HeaderCart />
              </div>
            </Wrapper>
          </nav>
          <div
            className={classNames("overlay", { active: isOpen })}
            onClick={() => setOpen(false)}
            data-hidden-desktop
          ></div>
          <HeaderCategories
            isCategoriesOpen={isOpenCategories}
            openCategory={() => setOpenCategories(!isOpenCategories)}
          />
        </Container>
      </Header>
      <DialogCatalogComponent
        active={isCatalogOpen}
        onDismiss={() => setCatalogOpen(false)}
      />
    </>
  );
}
