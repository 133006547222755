import styled from "@emotion/styled";
import { useMediaQuery } from "../utils/responsive.emotion";

export const Footer = styled.footer`
  position: relative;

  .footer {
    &__section {
      h2 {
        margin-bottom: 1.25rem;
        color: ${(props) => props.theme.colors.sand};
        font-weight: 800;
        text-transform: uppercase;
      }

      & + .footer__section {
        margin-top: 3.125rem;
      }

      a {
        color: ${(props) => props.theme.colors.white};
        transition: var(--transition-base);

        .fa-brands {
          margin-right: 0.5rem;
        }

        &:hover {
          color: ${(props) => props.theme.colors.sand};
        }
      }

      ul {
        li {
          display: block;

          & + li {
            margin-top: 1rem;
          }
        }
      }

      img {
        max-width: 260px;
        object-fit: contain;
      }
    }
  }

  .main {
    background-color: ${(props) => props.theme.colors.dark};
    padding: calc(3.4vw + 70px) 0 3.4vw;
    color: ${(props) => props.theme.colors.white};
  }

  .bottom {
    padding: 1.25rem 0;
    background-color: ${(props) => props.theme.colors.black};

    span,
    a {
      color: ${(props) => props.theme.colors.white};
      font-size: 0.9em;
      font-weight: 300;
    }
    a {
      &:hover {
        text-decoration: underline;
      }
    }

    .yourway-dev {
      span {
        font-weight: 300;
      }

      img {
        max-width: 120px;
        object-fit: contain;
      }
    }
  }

  #adopt-controller-button {
    z-index: 1 !important;
  }

  ${() => useMediaQuery("sm")} {
    .col {
      max-width: 25%;
    }
  }

  ${() => useMediaQuery("sm", "max")} {
    .main {
      padding-top: 8rem;
    }
    .footer {
      &__section {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
    }
    .bottom {
      span {
        display: block;
      }
    }
  }
`;
