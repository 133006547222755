import Image from "next/image";
import Link from "next/link";
import * as React from "react";
import VMasker from "vanilla-masker";
import { useGlobal } from "../context/global.context";
import { Footer } from "../styles/components/footer.emotion";
import { Container, Wrapper } from "../styles/utils/global.emotion";
import { Description } from "../styles/utils/typograth.emotion";
import WhatsappButtonComponent from "./utils/whatsapp-component";
import DialogFormComponent from "./dialog/dialog-form.component";

export default function FooterComponent() {
  const { contact } = useGlobal();
  const [open, setOpen] = React.useState(false);

  return (
    <Footer>
      <DialogFormComponent
        contact={contact}
        active={open}
        onDismiss={() => setOpen(!open)}
      />
      <WhatsappButtonComponent onDismiss={() => setOpen(!open)} />
      <div className="main">
        <Container>
          <Wrapper
            flexDirection="row"
            alignItems="flex-start"
            gap="max(2rem, 2.6vw)"
            justifyContent="space-between"
            mobileOptions={{
              flexDirection: "column",
              gap: "4rem",
              alignItems: "center",
            }}
          >
            <div className="col">
              <div className="footer__section">
                <h2>PRECISA DE AJUDA?</h2>
                <ul>
                  <li>
                    <Link
                      href="/institucional/[slug]"
                      as={`/institucional/prazo-entrega`}
                    >
                      Prazo de entrega
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/institucional/[slug]"
                      as={`/institucional/como-comprar`}
                    >
                      Como comprar
                    </Link>
                  </li>
                  <li>
                    <Link href="/institucional/trocas-e-devolucoes">
                      Trocas e devoluções
                    </Link>
                  </li>
                  <li>
                    <Link href="/institucional/compra-e-envio">
                      Compra e envio
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/institucional/[slug]"
                      as={`/institucional/perguntas-frequentes`}
                    >
                      Perguntas frequentes
                    </Link>
                  </li>
                </ul>
              </div>

              {contact?.phone && (
                <div className="footer__section">
                  <h2>TELEFONE</h2>
                  <a href={`tel:+55${contact?.phone}`}>
                    {VMasker.toPattern(
                      contact?.phone,
                      contact?.phone?.length === 11
                        ? "99 9 9999 9999"
                        : "99 9999 99999"
                    )}
                  </a>
                </div>
              )}

              {contact?.email && (
                <div className="footer__section">
                  <h2>E-MAIL</h2>
                  <a href={`mailto:${contact?.email}`}>{contact?.email}</a>
                </div>
              )}
            </div>
            <div className="col">
              <div className="footer__section">
                <h2>Site map</h2>
                <ul>
                  <li>
                    <Link href="/">Home</Link>
                  </li>
                  <li>
                    <Link href="/produtos">Produtos</Link>
                  </li>
                </ul>
              </div>
              {contact?.openingHours && (
                <div className="footer__section">
                  <h2>Horários de antedimento</h2>
                  <Description color="white" size="small">
                    {contact?.openingHours}
                  </Description>
                </div>
              )}
            </div>
            {contact?.facebook || contact?.instagram || contact?.linkedin ? (
              <div className="col">
                <div className="footer__section">
                  <h2>REDES SOCIAIS</h2>
                  <ul>
                    {contact?.instagram && (
                      <li>
                        <Link href={contact?.instagram} target={"_blank"}>
                          <i className="fa-brands fa-instagram"></i>
                          <span>/bomarzodesign</span>
                        </Link>
                      </li>
                    )}

                    {contact?.facebook && (
                      <li>
                        <Link href={contact?.facebook} target={"_blank"}>
                          <i className="fa-brands fa-facebook-square"></i>
                          <span>/bomarzodesign</span>
                        </Link>
                      </li>
                    )}

                    {contact?.tiktok && (
                      <li>
                        <Link href={contact?.tiktok} target={"_blank"}>
                          <i className="fa-brands fa-tiktok"></i>
                          <span>@BomarzoDesign</span>
                        </Link>
                      </li>
                    )}

                    {contact?.linkedin && (
                      <li>
                        <Link href={contact?.linkedin} target={"_blank"}>
                          <i className="fa-brands fa-linkedin"></i>
                          <span>/bomarzo</span>
                        </Link>
                      </li>
                    )}

                    <li>
                      <Link
                        href={"https://www.youtube.com/@BomarzoDesign"}
                        target={"_blank"}
                      >
                        <i className="fa-brands fa-youtube"></i>
                        <span>@bomarzodesign</span>
                      </Link>
                    </li>

                    <li>
                      <Link
                        href={"https://br.pinterest.com/bomarzodesign/"}
                        target={"_blank"}
                      >
                        <i className="fa-brands fa-pinterest"></i>
                        <span>/bomarzodesign</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            ) : null}
            <div className="col">
              <div className="footer__section">
                <h2>FORMAS DE PAGAMENTO</h2>
                <Image
                  src="/formasdepagamentovolk.webp"
                  alt="Formas de Pagamento"
                  width={317}
                  height={141}
                  style={{ backgroundColor: "#fff" }}
                />
              </div>
            </div>
          </Wrapper>
          <Wrapper
            justifyContent="space-between"
            alignItems="flex-end"
            style={{ marginTop: "3.125rem" }}
            gap="2.125rem"
            mobileOptions={{
              flexDirection: "column-reverse",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Wrapper flexDirection="column" gap="1.125rem">
              <span>CNPJ: 51.327.288/0001-44 </span>
              <span>
                Razão Social: Bomarzo Comercio de Artigos de Decoracao Ltda{" "}
              </span>
            </Wrapper>
            <div className="footer__section">
              <h2>SEGURANÇA</h2>
              <Image
                src="/selo-ssl.png"
                alt="Selo SSL"
                width={350}
                height={120}
              />
            </div>
          </Wrapper>
        </Container>
      </div>
      <div className="bottom">
        <Container>
          <Wrapper
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mobileOptions={{ flexDirection: "column", gap: "2rem" }}
          >
            <span>
              © {new Date().getFullYear()}. Bomarzo.{" "}
              <Link href={"/politica-de-privacidade"}>
                Política de privacidade
              </Link>
            </span>
            <div className="yourway-dev">
              <span>Desenvolvido por&nbsp;</span>
              <a
                href="https://www.yourway.digital"
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  src="/yourway.png"
                  alt="YourWay Digital #doSeuJeito"
                  width={112.5}
                  height={21.6}
                />
              </a>
            </div>
          </Wrapper>
        </Container>
      </div>
    </Footer>
  );
}
