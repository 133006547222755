import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useMediaQuery } from "../../utils/responsive.emotion";

export const Header = styled.div<{
  active?: boolean;
}>`
  position: relative;

  nav {
    padding: 1.25em 0;

    .logo {
      display: block;
      max-width: 180px;

      img {
        object-fit: contain;
      }
    }

    ul {
      flex-grow: 1;
      margin-left: 3.125rem;
      display: flex;
      align-items: center;

      li {
        display: inline-block;
        font-size: 0.8rem;

        button {
          margin-top: 0;
          margin-left: 2.25rem;
          background-color: ${(props) => props.theme.colors.green};
          color: ${(props) => props.theme.colors.white};
          padding: 0.625rem 1.65rem;
          font-weight: 600;
          border: 1px solid ${(props) => props.theme.colors.green};
          box-shadow: 0px 8px 10px #15151920;

          span {
            font-size: 0.8rem;
            text-transform: uppercase;
          }

          &:hover {
            background-color: transparent;
            color: ${(props) => props.theme.colors.green};
          }
        }

        a {
          color: ${(props) => props.theme.colors.text};
          line-height: 2.125rem;
          display: block;
          padding: 0 1.25em;
          position: relative;

          span {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 0.8rem;
          }

          &:hover {
            color: ${(props) => props.theme.colors.green};
          }

          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0.1rem;
            background-color: ${(props) => props.theme.colors.green};
            transform: scaleX(0);
            transition: var(--transition-base);
          }

          &:hover {
            &::before {
              transform: scaleX(1);
            }
          }
        }

        &.active {
          a {
            &::before {
              transform: scaleX(1);
            }
          }
        }
      }
    }
  }

  .cart {
    position: relative;
    .fa-solid {
      font-size: 18px;
      color: ${(props) => props.theme.colors.dark};
    }

    &__badge {
      position: absolute;
      bottom: -6px;
      right: -6px;
      width: 16px;
      height: 16px;
      font-size: 12px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${(props) => props.theme.colors.white};
      background-color: ${(props) => props.theme.colors.danger};
      border-radius: 50%;
    }
  }

  ${(props) =>
    props.active &&
    css`
      /* do something */
    `}

  ${() => useMediaQuery("sm")} {
    .cart {
      margin-left: 16px;
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border: 1px solid ${(props) => props.theme.colors.dark};
      background-color: transparent;
      transition: all 200ms;
      cursor: pointer;

      &__badge {
        border-radius: 0px;
        bottom: -1px;
        right: -1px;
      }

      &:hover {
        background-color: ${(props) => props.theme.colors.dark};

        .fa-solid {
          color: ${(props) => props.theme.colors.white};
        }
      }
    }
  }

  ${() => useMediaQuery("sm", "max")} {
    nav {
      padding: 0;

      ul {
        align-items: flex-start;

        li {
          button {
            margin-top: 2.125rem;
            margin-left: 0;
          }
        }
      }
    }

    .menu-institutional {
      position: fixed;
      top: 0;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      left: 0;
      width: 300px;
      min-height: 100vh;
      z-index: 5;
      margin-left: 0;
      padding: 2rem;
      transform: translate(-300px, 0);
      &.active {
        transform: translate(0, 0);
        transition: var(--transition-base);
      }
    }
  }
`;
