import styled from "@emotion/styled";
import { useMediaQuery } from "../../utils/responsive.emotion";

export const HeaderSearch = styled.div`
  &:focus-within {
    border-bottom-color: ${(props) => props.theme.colors.green};
  }

  ${() => useMediaQuery("sm")} {
    border-bottom: 1px solid ${(props) => props.theme.colors.dark};

    input {
      height: 3.125em;
      min-width: 20vw;
    }
  }

  ${() => useMediaQuery("sm", "max")} {
    .toggle-search {
      .fa-solid {
        font-size: 18px;
      }
    }

    form {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${(props) => props.theme.colors.white};
      padding: 0 5%;
      z-index: 2;
      transform: translateY(-100%);
      transition: all 300ms;

      input {
        width: 100%;
        height: 56px;
      }

      &.active {
        transform: translateY(0);
      }
    }

    .overlay {
      height: calc(100% - 56px);
      top: 56px;

      &.active {
        opacity: 0.2;
      }
    }
  }
`;
